jQuery( document ).ready(function( $ ) {

        var sidebar_width = $('.sidebar').width();
        var iframe_src = $('.album-featured img').data("src")
        $('.album-featured div').html('<iframe class="embed" src="'+iframe_src+'" frameborder="0" seamless></iframe>');
        $(".album-featured iframe").width(sidebar_width).height(sidebar_width);
        $(".album-featured").height(sidebar_width-15);
        if ($(window).width() <= 360) {
                //$(".main-content").css("margin-top", $(".mobile").height());

                var mobile_header = document.getElementById("mobile");

                var headroom = new Headroom(mobile_header, {
                        "offset": 150,
                        "tolerance": 5,
                        "classes": {
                                "initial": "animated",
                                "pinned": "bounceInDown",
                                "unpinned": "bounceOutUp"
                        }
                });
                headroom.init();
        }

        $(window).resize(function() {

                if ($(window).width() <= 360) {
                        var headroom = new Headroom(mobile_header, {
                                "offset": 0,
                                "tolerance": 5,
                                "classes": {
                                        "initial": "animated",
                                        "pinned": "slideDown",
                                        "unpinned": "slideUp"
                                }
                        });
                        headroom.init();
                        //$(".main-content").css("marginTop", $(".mobile").height());
                }


                var sidebar_width = $('.sidebar').width();
                $(".album-featured").height(sidebar_width-15);
                $(".album-featured iframe").width(sidebar_width).height(sidebar_width);
                if ($(window).width() <= 480) {
                        //$(".main-content").css("margin-top", $(".mobile").height());
                }
        });
});